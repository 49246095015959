
import { Component, Vue } from 'vue-property-decorator'
import { Balance, Seller } from '@/models'
import { useBalanceStore } from '@/stores/balance'

@Component({
  name: 'PrepaidAccount',
  components: {
    BalanceHeader: () => import('./components/BalanceHeader.vue'),
    BalanceCard: () => import('./components/BalanceCard.vue'),
    BalanceMovesTable: () => import('./BalanceMovesView/BalanceMovesTable.vue'),
    BalanceMovesFilters: () => import('./BalanceMovesView/FiltersDrawer.vue'),
  }
})
export default class extends Vue {
  balanceStore: any = useBalanceStore()

  private sellersLoading = false
  private sellers: Seller[] = []

  private globalView = false

  get balances(): Balance[] {
    return this.balanceStore.balances
  }

  get totalBalances(): number {
    return this.balanceStore.totalBalances
  }

  get loading(): boolean {
    return this.balanceStore.balancesLoading
  }

  get filters(): any {
    return this.balanceStore.filters
  }

  set filters(value: any) {
    this.balanceStore.filters = value
  }

  async created(): Promise<void> {
    await this.balanceStore.fetchBalances()
    await this.getSellers()
  }

  private async getSellers() {
    this.sellersLoading = true

    const { data } = await Seller
      .page(1)
      .per(999)
      .order({ name: 'asc' })
      .all()

    this.sellers = data
    this.sellersLoading = false
  }

  moreBalances(): void {
    this.balanceStore.pagination.page += 1
    this.balanceStore.fetchBalances()
  }

  setFilters() {
    this.balanceStore.resetPagination()

    if (this.globalView) this.balanceStore.fetchBalanceMoves()
    else this.balanceStore.fetchBalances()
  }

  refresh() {
    this.balanceStore.resetFilters()
    this.balanceStore.resetPagination()

    if (this.globalView) this.balanceStore.fetchBalanceMoves()
    else this.balanceStore.fetchBalances()
  }

  seeGlobalView() {
    this.balanceStore.resetFilters()
    this.balanceStore.resetPagination()
    this.globalView = !this.globalView

    if (this.globalView) this.balanceStore.fetchBalanceMoves()
    else this.balanceStore.fetchBalances()
  }

  beforeDestroy() {
    this.balanceStore.clearSettings()
  }
}


import { Component, Vue } from 'vue-property-decorator'
import { Buyer, GiftCard } from '@/models'

import { useUserStore } from '@/stores/user'
import { useBalanceStore } from '@/stores/balance'

@Component({
  name: 'FiltersDrawer',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue')
  }
})
export default class extends Vue {
  userStore: any = useUserStore()
  balanceStore: any = useBalanceStore()

  private showFilters = false

  private giftCards: GiftCard[] = []
  private giftCardsLoading = false
  private paginationGiftCards = {
    page: 1,
    perPage: 999
  }

  private buyers: Buyer[] = []
  private buyersLoading = false

  private kind = {
    vat: this.$t('kindPrepaidAccount.vat'),
    overpayment: this.$t('kindPrepaidAccount.overpayment'),
    order_payment: this.$t('kindPrepaidAccount.orderPayment'),
    topup: this.$t('kindPrepaidAccount.topup'),
    fix: this.$t('kindPrepaidAccount.fix'),
    pull: this.$t('kindPrepaidAccount.pull'),
    order_payment_cancellation: this.$t('kindPrepaidAccount.orderPaymentCancellation'),
    topup_cancellation: this.$t('kindPrepaidAccount.topupCancellation'),
    pull_refund: this.$t('kindPrepaidAccount.pullRefund'),
    card_refund: this.$t('kindPrepaidAccount.cardRefund')
  }

  private reasonOptions = [
    {
      label: this.$t('kindPrepaidAccount.pull'),
      value: 'pull'
    },
    {
      label: this.$t('kindPrepaidAccount.overpayment'),
      value: 'overpayment'
    },
    {
      label: this.$t('kindPrepaidAccount.orderPayment'),
      value: 'order_payment'
    },
    {
      label: this.$t('kindPrepaidAccount.vat'),
      value: 'vat'
    },
    {
      label: this.$t('kindPrepaidAccount.topup'),
      value: 'topup'
    },
    {
      label: this.$t('kindPrepaidAccount.fix'),
      value: 'fix'
    },
    {
      label: this.$t('kindPrepaidAccount.orderPaymentCancellation'),
      value: 'order_payment_cancellation'
    },
    {
      label: this.$t('kindPrepaidAccount.topupCancellation'),
      value: 'topup_cancellation'
    },
    {
      label: this.$t('kindPrepaidAccount.pullRefund'),
      value: 'pull_refund'
    },
    {
      label: this.$t('kindPrepaidAccount.cardRefund'),
      value: 'card_refund'
    }
  ]

  get filters(): any {
    return this.balanceStore.filters
  }

  set filters(filters: any) {
    this.balanceStore.filters = filters
  }

  get filtersActive() {
    return Object.keys(this.filters).filter(i => {
      if (this.filters[i] !== null) {
        if ((typeof this.filters[i] === 'object' && this.filters[i].length > 0) || typeof this.filters[i] !== 'object') {
          if (i !== 'page') {
            return i
          }
        }
      }
    }).length
  }

  get isWholesaler() {
    return this.userStore.roles.includes('wholesaler')
  }

  private created() {
    this.getGiftCards()
    this.getBuyers()
  }

  private async getGiftCards() {
    this.giftCardsLoading = true

    const { data } = await GiftCard
      .per(this.paginationGiftCards.perPage)
      .page(this.paginationGiftCards.page)
      .order('name')
      .stats({ total: 'count' })
      .all()

    this.giftCards.push(...data)

    this.giftCardsLoading = false
  }

  private async getBuyers() {
    this.buyersLoading = true

    const { data } = await Buyer.per(999).all()

    this.buyers = data
    this.buyersLoading = false
  }

  private handleGiftCardSelect(giftCardId: string) {
    this.filters.giftCardId = giftCardId
  }

  refresh() {
    this.showFilters = false
    this.$emit('applyFilters', this.filters)
  }
}

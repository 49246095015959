
import { Component, Prop, Vue } from 'vue-property-decorator'
import { BalanceMove } from '@/models'

import BalanceMoveCard from './BalanceMoveCard.vue'
import { clipboardSuccess } from '@/utils/clipboard'

@Component({
  name: 'BalanceMoveCard',
  components: {
    BalanceMoveCard
  }
})
export default class extends Vue {
  @Prop({ required: true }) private balanceMove!: BalanceMove
  @Prop({ required: false, default: false }) private nested!: boolean

  private clipboardSuccess = clipboardSuccess

  private kind = {
    vat: this.$t('kindPrepaidAccount.vat'),
    overpayment: this.$t('kindPrepaidAccount.overpayment'),
    order_payment: this.$t('kindPrepaidAccount.orderPayment'),
    topup: this.$t('kindPrepaidAccount.topup'),
    fix: this.$t('kindPrepaidAccount.fix'),
    pull: this.$t('kindPrepaidAccount.pull'),
    order_payment_cancellation: this.$t('kindPrepaidAccount.orderPaymentCancellation'),
    topup_cancellation: this.$t('kindPrepaidAccount.topupCancellation'),
    pull_refund: this.$t('kindPrepaidAccount.pullRefund'),
    card_refund: this.$t('kindPrepaidAccount.cardRefund')
  }

  private balanceMoveReasonType = {
    vat: 'badge-vat',
    overpayment: 'badge-warning',
    pull: 'badge-debit',
    fix: 'badge-warning',
    order_payment: 'badge-action',
    topup: 'badge-success',
    order_payment_cancellation: 'badge-warning',
    topup_cancellation: 'badge-warning',
    pull_refund: 'badge-refund',
    card_refund: 'badge-card-refund'
  }

  setClassTrend(balanceMove: BalanceMove): string {
    if (balanceMove.amount < 0) return 'badge-debit'
    else return 'badge-success'
  }

  setIconTrend(balanceMove: BalanceMove): string {
    if (balanceMove.amount < 0) return 'el-icon-bottom-left'
    else return 'el-icon-top-right'
  }
}

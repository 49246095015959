
import { Component, Prop, Vue } from "vue-property-decorator";
import { BalanceMove } from '@/models'

import { useUserStore } from '@/stores/user'
import { useBalanceStore } from '@/stores/balance'

import BalanceMoveCard from './BalanceMoveCard.vue'

@Component({
  name: 'BalanceMoves',
  components: {
    BalanceMoveCard
  }
})
export default class extends Vue {
  @Prop({ required: false, default: false }) private isBuyer!: boolean

  userStore: any = useUserStore()
  balanceStore: any = useBalanceStore()

  get isWholesaler() {
    return this.userStore.roles.includes('wholesaler')
  }

  get balanceMoves(): BalanceMove[] {
    return this.balanceStore.balanceMoves
  }

  get headersByDate(): BalanceMove[] {
    return this.balanceStore.headersByDate
  }

  get balanceMovesLoading(): boolean {
    return this.balanceStore.balanceMovesLoading
  }

  get total(): number {
    return this.balanceStore.total
  }

  get pagination(): any {
    return this.balanceStore.pagination
  }

  getBalanceMoves(): void {
    this.balanceStore.pagination.page += 1
    this.balanceStore.fetchBalanceMoves(this.isBuyer)
  }
}

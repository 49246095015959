
import { Component, Vue } from 'vue-property-decorator'
import { useBalanceStore } from '@/stores/balance'
import { Balance } from '@/models'

@Component({
  name: 'BalanceView',
  components: {
    BalanceMovesHeader: () => import('./BalanceMovesHeader.vue'),
    FiltersDrawer: () => import('./FiltersDrawer.vue'),
    BalanceMovesTable: () => import('./BalanceMovesTable.vue'),
  }
})
export default class extends Vue {
  balanceStore: any = useBalanceStore()

  get balanceLoading(): boolean {
    return this.balanceStore.balancesLoading
  }

  get balance(): Balance {
    return this.$route.params.id ? this.balanceStore.balanceById : this.balanceStore.balances[0]
  }

  get filters(): any {
    return this.balanceStore.filters
  }

  set filters(filters: any) {
    this.balanceStore.filters = filters
  }

  async created() {
    if (this.$route.params.id) await this.balanceStore.fetchBalanceById(this.$route.params.id)
    else await this.balanceStore.fetchBalances()

    this.balanceStore.selectedBalance = this.balance
    await this.balanceStore.fetchBalanceMoves()
  }

  setFilters() {
    this.balanceStore.resetPagination()
    this.balanceStore.fetchBalanceMoves()
  }

  refresh() {
    this.balanceStore.resetFilters()
    this.balanceStore.resetPagination()
    this.balanceStore.fetchBalanceMoves()
  }

  beforeDestroy() {
    this.balanceStore.resetFilters()
    this.balanceStore.resetPagination()
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import { Balance } from '@/models'

@Component({
  name: 'BalanceCard',
  components: {
    ExtractPrepaidAccountDialog: () => import('@/components/ExtractPrepaidAccountDialog/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private balance!: Balance

  private extractDialogVisible = false

}

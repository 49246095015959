
import { Component, Vue } from 'vue-property-decorator'
import { Form } from 'element-ui'
import { Balance } from '@/models'
import { useBalanceStore } from '@/stores/balance'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'BalanceMovesHeader',
  components: {
    ExtractPrepaidAccountDialog: () => import('@/components/ExtractPrepaidAccountDialog/index.vue')
  }
})
export default class extends Vue {
  balanceStore: any = useBalanceStore()
  userStore: any = useUserStore()

  private loadingBalance = false
  private extractDialogVisible = false

  private rules = {
    lowAmountThreshold: [
      { required: true, message: this.$t('prepaidAccount.header.alert.dialog.rules.required'), trigger: 'blur' }
    ]
  }

  get balance(): Balance {
    return this.balanceStore.selectedBalance
  }

  set balance(balance) {
    this.balanceStore.selectedBalance = balance
  }

  get isBuyer(): boolean {
    return this.userStore.roles.includes('buyer')
  }

  private updateBalance() {
    (this.$refs['balanceForm'] as Form).validate(async(valid) => {
      this.loadingBalance = true

      if (valid) {
        try {
          await this.balance.save()

          this.$notify({
            title: this.$t('prepaidAccount.header.alert.dialog.notification.success.title') as string,
            message: this.$t('prepaidAccount.header.alert.dialog.notification.success.subtitle') as string,
            type: 'success',
            duration: 2000
          });
          (this.$refs.alertDialog as any)!.doClose()
        } catch (err) {
          this.$notify({
            title: this.$t('prepaidAccount.header.alert.dialog.notification.error.title') as string,
            message: this.$t('prepaidAccount.header.alert.dialog.notification.error.subtitle') as string,
            type: 'success',
            duration: 2000
          })
          this.loadingBalance = false
        }
      }
      this.loadingBalance = false
    })
  }
}
